@import 'styles/layers.scss';

@layer component {
  .group {
    margin-top: 30px;
    column-gap: 10px;
  }

  .btn {
    width: 101px;
    height: 41px;
  }

  .notification {
    margin-top: 20px;
  }

  .text{
    margin: 0;
  }

  .link {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    &:hover, &:active {
      color: inherit;
    }
  }
}