.balance {
  position: relative;
}

.dark {
  .text {
    color: var(--white);
    opacity: 0.5;
  }
}

.light {
  .text {
    color: var(--black);
  }
}