@import 'styles/layers.scss';

@layer component {
  .content {
    min-width: 260px;
    width: 260px;
    padding: 0 0 0 20px;
    border-left: 1px solid var(--black11);
  }
  
  .paper {
    display: flex;
    background: transparent;
    flex-direction: column;
  }
  
  .params {
    row-gap: 15px;
  }
  
  .label {
    opacity: 0.3;
  }

  .added-box {
    background-color: var(--grey1);
    width: 100%;
    min-height: 99px;
    border-radius: 6px;
    padding: 12px;
  }
  
  .price-text {
    font-size: 14px;
    line-height: 26px;
  }

  .label-line {
    font-size: 14px;
  }

  .icon {
    color: var(--white);
  }
}
